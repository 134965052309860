
import http from "@/http";
import Vue from "vue";

import { mapState } from "vuex";

export interface DataType {
  id: string;
  name: string;
  email: string;
  valid: boolean;
  industries: string;
}

export default Vue.extend({
  data(): DataType {
    return {
      id: "",
      email: "",
      name: "",
      valid: false,
      industries: "",
    };
  },
  computed: {
    ...mapState(["companies"]),
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "業者",
          disabled: false,
          to: "/companies",
        },
        {
          text: "新規",
          disabled: true,
        },
      ];
    },
    rules() {
      return {
        required: [(v: string) => !!v || "必須です"],
      };
    },
    items(): string[] {
      return ["クリーニング", "コールセンター", "巡回"];
    },
    icon() {
      return {};
    },
  },
  methods: {
    async submit() {
      const { data } = await http.post(`companies`, {
        id: this.id,
        email: this.email,
        name: this.name,
        industries: this.industries,
      });

      await this.$router.push({
        path: "/companies",
      });
    },
  },
});
